<script lang="ts" setup>
import { provideApolloClient } from '@vue/apollo-composable';
import { useI18n } from 'vue-i18n';
import { useForm } from 'vee-validate';
import * as yup from 'yup';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import { FABRICATION_ORDER_INSERT, FABRICATION_ORDER_UPDATE } from '~/graphql/fabrication_orders';

dayjs.extend(advancedFormat);

// Composables & plugins
const { apolloClient } = useApollo();
const { t } = useI18n();
const { showToast } = toastStore();

provideApolloClient(apolloClient);

// Props & emits
const emits = defineEmits(['update']);
const modal = defineModel<boolean>();
const props = defineProps<{
  ofId: number | null;
}>();

// Stores
const { getMeter } = appStore();

// Data
const isSubmitting = ref(false);

// Form
const schema = yup.object().shape({
  reportingFrequency: yup.string(),
  start: yup.string().when('reportingFrequency', ([reportingFrequency], schema) => {
    return reportingFrequency === 'dateTime' ? schema.required(t('epi.form.start_date_is_required')) : schema.notRequired();
  }),
  end: yup.string().when(['reportingFrequency', 'start'], ([reportingFrequency, start], schema) => {
    if (reportingFrequency !== 'dateTime') return schema.notRequired();
    return schema
      .required(t('epi.form.end_date_is_required'))
      .test('is-after-start', t('epi.form.end_date_must_be_after_start'), function (value) {
        if (!value || !start) return true;
        return dayjs(value).isAfter(dayjs(start));
      });
  }),
  quantity: yup.number().required(t('epi.form.quantity_is_required')).typeError(t('epi.form.quantity_is_required')),
});
const { handleSubmit, values, setValues } = useForm<{
  reportingFrequency: string;
  start: string;
  end: string;
  quantity: number;
}>({
  validationSchema: schema,
});

// Computed
const title = computed(() => (props.ofId ? t('epi.edit_fabrication_order') : t('epi.add_fabrication_order')));
const unit = computed(() => areaStore().getAreaByMeterId(getMeter)?.fabrication_order_unit?.symbol ?? '--');
const reportingFrequency = computed(() => {
  return 'dateTime';
});

// Methods
const submit = handleSubmit(async (values) => {
  const start = dayjs(values.start).toDate();
  const end = dayjs(values.end).toDate();

  if (props.ofId) {
    await apolloClient.mutate({
      mutation: FABRICATION_ORDER_UPDATE,
      variables: {
        fabrication_order: {
          start,
          end,
          quantity: values.quantity,
        },
        id: props.ofId,
      },
    });
    showToast({ type: 'success', title: t('global.success'), message: t('epi.fabrication_order_updated') });
  } else {
    await apolloClient.mutate({
      mutation: FABRICATION_ORDER_INSERT,
      variables: {
        fabrication_order: {
          start,
          end,
          quantity: values.quantity,
          area_id: areaStore().getAreaByMeterId(getMeter).id,
        },
      },
    });
    showToast({ type: 'success', title: t('global.success'), message: t('epi.fabrication_order_created') });
  }
  emits('update');
  isSubmitting.value = false;
  modal.value = false;
});

watch(
  () => modal.value,
  () => {
    if (props.ofId) {
      const fabricationOrder = epiStore().getFabricationOrder(props.ofId);
      if (fabricationOrder) {
        setValues({
          reportingFrequency: reportingFrequency.value,
          start: fabricationOrder.start,
          end: fabricationOrder.end,
          quantity: fabricationOrder.quantity,
        });
      }
    }
  },
);

onMounted(() => {
  setValues({
    reportingFrequency: reportingFrequency.value,
  });
});
</script>

<template>
  <ui-modal v-model="modal" :title="title" width="500">
    <ui-form-input-date
      name="start"
      type="dateTime"
      :disabled-dates="[{ start: dayjs().add(1, 'day').toDate() }, { start: dayjs(values.end).add(1, 'day').toDate() }]"
      :label="t('global.start_date')"
    />
    <ui-form-input-date
      name="end"
      type="dateTime"
      :disabled-dates="[{ start: dayjs().add(1, 'day').toDate() }, { end: dayjs(values.start).subtract(1, 'day').toDate() }]"
      :label="t('global.end_date')"
    />
    <ui-form-input-text name="quantity" placeholder="0" type="number" :right-text="unit" :label="t('global.quantity')" />

    <template #footer>
      <ui-button color="secondary" @click="modal = false">
        {{ t('global.cancel') }}
      </ui-button>
      <ui-button color="primary" :loading="isSubmitting" @click="submit">
        {{ t('global.save') }}
      </ui-button>
    </template>
  </ui-modal>
</template>
