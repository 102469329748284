<script setup lang="ts">
import dayjs from 'dayjs';

// Components
import { useI18n } from 'vue-i18n';
import EpiOFZoomModal from '../modal/EpiOFGraphModal.vue';
import type { ChartOptions } from 'chart.js';

// Composables
const { t } = useI18n();
const { formatNumberToIsoEuroNumber } = useNumbers();
const service = epiStore();
const route = useRoute();
const { getUnitsByMeter } = useUnits();

// Data
const alertModal = ref<boolean>(false);
const ofModal = ref<boolean>(false);
const ofId = ref<number | null>(null);
const ofCost = ref<string | null>(null);
const resetZoom = ref(false);

// Methods
const openOFModal = (index: number) => {
  if (!service.graphFormatEPIResponse) return;
  const production = service.fabricationOrdersFiltered[index].quantity;
  const costByQuantity = service.fabricationOrdersFiltered[index].fabrication_order_epis[0].cost_by_quantity;
  ofId.value = service.fabricationOrdersFiltered[index].id;
  ofCost.value =
    formatNumberToIsoEuroNumber(costByQuantity * production) + ` (${costByQuantity.toFixed(2)} € / ${getUnitsByMeter.value.symbol})`;
  ofModal.value = true;
};

// Computed
const alert = computed(() => alertStore().getAlertByMeterIdAndType(parseInt(route.params.id as string), 'EPI'));

const getColotFromData = computed(() => {
  const response = service.graphFormatEPIResponse;
  return response.epis.map((epi: number, index: number) => {
    const product = service.fabricationOrdersFiltered[index];
    if (product.has_up_to_date_epi && product.fabrication_order_epis[0].consumption_kwh > 0) {
      return '#16a34a';
    }
    return '#64748b';
  });
});

const options = computed((): ChartOptions => {
  return {
    scales: {
      y: {
        beginAtZero: true,
        title: {
          display: true,
          padding: 12,
        },
        grace: '25%',
      },
      x: {
        ticks: {
          callback: (value: any) => {
            if (!service.loading) {
              return dayjs(service.graphFormatEPIResponse.timestamps[value]).format('DD/MM/YYYY HH:mm');
            }
          },
        },
      },
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: (context: any) => {
            const product = service.fabricationOrdersFiltered[context.dataIndex];
            if (!product.has_up_to_date_epi) return t('epi.epi_calculating');
            if (product.fabrication_order_epis[0].consumption_kwh === 0) return t('global.no_data');
            return `${context.formattedValue} kWh / ${getUnitsByMeter.value.symbol}`;
          },
          title: (context: any) => {
            const product = service.fabricationOrdersFiltered[context[0].dataIndex];
            if (!product.has_up_to_date_epi) return '';
            return dayjs(service.graphFormatEPIResponse.timestamps[context[0].dataIndex]).format('DD/MM/YYYY HH:mm');
          },
          footer: (context: any) => {
            const product = service.fabricationOrdersFiltered[context[0].dataIndex];
            if (!product.has_up_to_date_epi) return '';
            if (!service.graphFormatEPIResponse) return;

            const fabrication_order = service.fabricationOrdersFiltered[context[0].dataIndex];
            const consumption = fabrication_order.fabrication_order_epis[0].consumption_kwh.toFixed(0);
            const production_kg = fabrication_order.quantity;
            const start = dayjs(fabrication_order.start).format('DD/MM/YYYY HH:mm');
            const end = dayjs(fabrication_order.end).format('DD/MM/YYYY HH:mm');

            const productIds = fabrication_order.fabrication_order_products.map((p) => `ProductId: ${p.product?.client_product_id}`);
            const productIdTooltip = fabrication_order.fabrication_order_products.length > 0 ? [...productIds] : [];

            const costTooltip = fabrication_order.fabrication_order_epis[0].cost_by_quantity
              ? [
                  `${t('global.cost_per_unit')}: ${fabrication_order.fabrication_order_epis[0].cost_by_quantity.toFixed(
                    2,
                  )} € / ${getUnitsByMeter.value.symbol}`,
                ]
              : [];

            const co2Value = useCO2().getCo2byKWhByMeterId(parseInt(route.params.id as string));

            const c02 =
              consumption > 0
                ? [
                    `${t('global.co2_pear_piece')}: ${(fabrication_order.fabrication_order_epis[0].epi * co2Value).toFixed(
                      2,
                    )} gCo2 / ${fabrication_order.area?.fabrication_order_unit?.symbol ?? 'kg'}`,
                  ]
                : [];

            return [
              `${t('global.Start')}: ${start}`,
              `${t('global.End')}: ${end}`,
              `${t('analytics.consumption')}:  ${consumption > 0 ? consumption + ' kWh' : t('global.no_data')}`,
              `${t('analytics.productions_kg')}: ${production_kg.toFixed(2)} ${fabrication_order.area?.fabrication_order_unit?.symbol ?? 'kg'}`,
            ]
              .concat(productIdTooltip, costTooltip, c02)
              .join('\n');
          },
        },
      },
      annotation: {
        annotations: {
          energyLimitPriceAnnotation: {
            display: alert.value ? alert.value.params.standard_value : 0,
            type: 'line',
            borderColor: 'rgba(150, 0, 0, 0.6)',
            borderWidth: 2,
            scaleID: 'y',
            value: alert.value ? alert.value.params.standard_value : 0,
          },
        },
      },
    },
  };
});

watch(
  () => appStore().getExploreDateRange,
  () => {
    resetZoom.value = true;
  },
);
</script>

<template>
  <div v-if="service.graphFormatEPIResponse.timestamps.length !== 0 && !service.loading">
    <ui-info
      v-if="alert"
      class="mb-4"
      :message="$t('global.alert_set_as', { value: alert?.params.standard_value }) + ' kWh / ' + getUnitsByMeter?.symbol"
      :link="$t('global.update')"
      @click-link="alertModal = true"
    />
    <ui-info v-else class="mb-4" :link="$t('global.create')" :message="$t('global.no_alert_set')" @click-link="alertModal = true" />
  </div>

  <div class="flex flex-1 flex-col-reverse lg:flex-row gap-2.5 lg:gap-6">
    <div class="flex flex-col w-full gap-2 max-h-xl rounded">
      <ui-warning
        v-if="service.graphFormatEPIResponse.timestamps.length === 0 && !service.loading"
        :message="$t('IPE.no_fo_selected_period')"
      />
      <div class="border border-gray-200 rounded-lg p-4">
        <h3 class="text-xs text-gray-400 mb-4">{{ $t('production.kwh_by_unit', { value: area?.production_unit?.symbol ?? '--' }) }}</h3>
        <ui-chart-global
          :loading="service.loading"
          :data="{
            labels: service.graphFormatEPIResponse.timestamps,
            datasets: [
              {
                data: service.graphFormatEPIResponse.epis,
                label: $t('global.consumption'),
                backgroundColor: getColotFromData,
                borderRadius: 2,
                hoverBackgroundColor: '#78716c',
                minBarLength: 4,
              },
            ],
          }"
          type="bar"
          :options="options"
          :plugins="['chartjs-plugin-zoom', 'chartjs-plugin-annotation']"
          :reset-zoom="resetZoom"
          @bar-click="openOFModal($event)"
        />
      </div>
    </div>
  </div>

  <!-- Modal for consumption Graph -->
  <AlertModal v-if="alertModal" v-model="alertModal" type="EPI" :alert-id="alert?.id" />

  <!-- Modal for zoom on a OF -->
  <EpiOFZoomModal v-if="ofModal && ofId" v-model="ofModal" :of-id="ofId" :cost="ofCost" />
</template>
