<script lang="ts" setup>
import type { IconName } from '../Icon.vue';

export interface ButtonGroup {
  key: string | number;
  label?: string;
  icon?: IconName;
}

// Props
const modelValue = defineModel<string | number>({ required: true });
const props = defineProps<{
  items: ButtonGroup[];
}>();
</script>
<template>
  <div class="flex">
    <div v-for="(item, index) in props.items" :key="item.key">
      <div
        :label="item.label"
        :icon="item.icon"
        class="border border-gray-200 px-2 h-[32px] flex items-center text-gray-600 cursor-pointer hover:bg-gray-50"
        :class="[
          index === 0 ? 'rounded-l-md border-r-0' : '',
          index === props.items.length - 1 ? 'rounded-r-md border-l' : '',
          modelValue === item.key ? 'bg-gray-100 text-gray-600 hover:bg-gray-100' : '',
        ]"
        @click="modelValue = item.key"
      >
        <ui-icon v-if="item.icon" :name="item.icon" class="w-5 h-5" />
        <p class="text-sm">{{ item.label }}</p>
      </div>
    </div>
  </div>
</template>
