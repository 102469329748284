<script setup lang="ts">
// Components
import AnalyticsIpeGraph from '@/app-modules/explore/components/graphs/EpiGraph.vue';
import AnalyticsIpeTable from '@/app-modules/explore/components/tables/IpeTable.vue';
import type { ButtonGroup } from '~/components/ui/button/Group.vue';

// Composables
const epiService = epiStore();

// Data
const selectedView = ref('list');
const items: ButtonGroup[] = [
  { key: 'list', icon: 'List' },
  { key: 'chart', icon: 'BarChart' },
];

// Computed
const meter = computed(() => meterStore().getMeter(appStore().getMeter));
const area = computed(() => areaStore().getAreaByMeterId(meter.value?.id));

// Lifecycle
onMounted(async () => {
  await epiService.fetchFabricationOrders();
});

watch(
  () => [appStore().getExploreDateRange, appStore().getMeter],
  async () => {
    await epiService.fetchFabricationOrders();
  },
);
</script>

<template>
  <div class="flex flex-col gap-4">
    <ui-card :title="$t('fabrication_order.fabrication_orders')">
      <template #header>
        <ui-search-bar v-model="epiService.search" :placeholder="$t('global.find_product')" />
        <ui-button-group v-model="selectedView" :items="items" />
      </template>

      <ui-warning
        v-if="!area?.fabrication_order_unit_id"
        :message="$t('configuration.your_area_is_not_configured_for_fabrication_order')"
        :link="$t('configuration.configure_area')"
        @click-link="$router.push({ name: 'area-manage', params: { id: area?.id } })"
      />

      <analytics-ipe-graph v-else-if="meter?.meter_type.energy_type !== 'Gas' && selectedView === 'chart'" />
      <analytics-ipe-table v-else-if="meter?.meter_type.energy_type !== 'Gas' && selectedView === 'list'" />
    </ui-card>

    <!-- Warning no IPE -->
    <ui-warning v-if="meter?.meter_type.energy_type === 'Gas'" :title="$t('epi.no_ipe_gas_title')" :message="$t('epi.no_ipe_gas')" />
  </div>
</template>
